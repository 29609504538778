<template>
    <div>
        <div>
            <wd-cell-group class="mys-reserve-act-content-form" style="margin-top: 32px;" title="预约人信息" border>
                <wd-input label="预约人姓名" required label-width="90px" v-model="searchData.name" />
                <wd-input label="身份证号" required label-width="90px" v-model="searchData.id_code" />
            </wd-cell-group>
            <div class="mys-reserve-act-button">
                <wd-button :disabled="loading" @click="searchRecord()">查询预约记录</wd-button>
            </div>
        </div>
        <div v-if="loaded">
            <wd-card style="margin-top: 16px; padding-bottom: 0px;" v-for="item in reservationData" :key="item.id">
                <span slot="title">
                    <template v-if="item.type == 'personal'">
                        <wd-tag size="small" type="primary" style="margin-right: 6px;">个人预约</wd-tag>
                        {{ item.name }}的预约
                    </template>
                    <template v-else-if="item.type == 'group'">
                        <wd-tag size="small" type="success" style="margin-right: 6px;">团体预约</wd-tag>
                        {{ item.group_name }}的预约
                    </template>
                    <template v-else>
                        <wd-tag size="small" type="danger" style="margin-right: 6px;">未知类型</wd-tag>
                    </template>
                </span>
                <div>日期：{{ item.date }}</div>
                <div>时间：<span style="color: #4f7cf8;">{{ item.time }}</span></div>
                <div v-if="item.type == 'group'">人数：{{ item.people_count }}</div>
                <div v-if="item.type == 'group'">所属单位：{{ item.group_name }}</div>
                <!-- <div v-if="item.type == 'group'">所属单位：<span style="color: #e63e46;">{{ item.group_name }}</span></div> -->
                <div slot="footer">
                    <!-- <template v-if="item.status == 0">
                        <wd-button size="small" plain @click="isShowKf = true">联系客服</wd-button>
                    </template> -->
                    <!-- <wd-button size="small" v-else-if="item.status == 1" @click="isShowPz = true">预约凭证</wd-button> -->
                    <!-- <wd-button size="small" plain @click="isShowKf = true">联系客服</wd-button> -->
                    <wd-button size="small" plain style="margin-right: 6px;" @click="cancelReservation(item.id, item.name, item.id_code)">撤销申请</wd-button>
                </div>
            </wd-card>
            <wd-card style="margin-top: 16px; padding-bottom: 0px;" v-if="!reservationData[0]">
                暂无预约记录
            </wd-card>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Common from "@/components/Common";
var apiurl = Common.APIurl;

export default {
    data: () => ({
        disabled: false,
        loaded: false,
        loading: false,
        searchData: {},
        reservationData: [],
        isShowPz: false,
    }),
    methods: {
        searchRecord() {
            this.loading = true;
            axios
                .get(apiurl + "Reservation/SearchReservation", {
                    params: {
                        name: this.searchData.name,
                        id_code: this.searchData.id_code,
                    }
                })
                .then((response) => {
                    if (response.data.code != 0) {
                        this.$toast.error(response.data.message);
                        this.loading = false;
                    } else {
                        this.$toast.success(response.data.message);
                        this.reservationData = response.data.data;
                        this.loading = false;
                        this.loaded = true;
                    }
                })
                .catch((error) => {
                    this.$toast.error(error.message);
                    this.loading = false;
                });
        },
        cancelReservation(reservation_id, name, id_code) {
            this.loading = true;
            axios
                .get(apiurl + "Reservation/CancelReservation", {
                    params: {
                        id: reservation_id,
                        name: name,
                        id_code: id_code,
                    },
                })
                .then((response) => {
                    if (response.data.code != 0) {
                        this.$toast.error(response.data.message);
                        this.loading = false;
                    } else {
                        this.$toast.success(response.data.message);
                        this.loading = false;
                    }
                })
                .catch((error) => {
                    this.$toast.error(error.message);
                    this.loading = false;
                });
        },
    },
};
</script>

<style>
.mys-reserve-act-large-card {
    margin-top: -128px;
    margin-left: 16px;
    margin-right: 16px;
    padding: 14px;
    min-height: 64px;
    text-align: center;
    background: #fff;
    border-radius: 8px;
}
.mys-reserve-act-large-card .title {
    font-size: 28px;
    font-weight: 600;
}
.mys-reserve-act-large-card .subtitle {
    text-transform: uppercase;
    font-size: 12px;
}
.mys-reserve-act-large-card .button {
    margin-top: 6px;
}
.mys-reserve-act-content-form {
    margin: 16px;
    border-radius: 8px;
}
.mys-reserve-act-button {
    text-align: center;
}
.mys-reserve-description-content-text {
    margin-top: 2px;
    max-height: 260px;
    overflow-y: auto;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
}

.wd-cell {
    background: transparent;
}
.wd-cell-group__body {
    background: transparent;
}
.wd-cell-group__title {
    background: transparent;
}
</style>